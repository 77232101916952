
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.page {
  &_view-ticket {
    @include for-size(phone-portrait-down) {
      margin-left: -20px;
      margin-right: -20px;
    }

    &__footer {
      @include for-size(phone-portrait-down) {
        position: fixed;
        left: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height: calc(100vh - #{$header-height});
        margin: 0;
        padding: 16px 20px;
        border-radius: $base-border-radius * 2 $base-border-radius * 2 0 0;
        box-shadow: 0px 8px 20px rgba(28, 41, 61, 0.2);
        z-index: 2;
      }
    }

    &__swiper {
      background: $color-white;
      display: flex;
      flex-direction: column;

      max-height: 50vh;
      flex-grow: 1;
      &__gesture-zone {
        position: relative;
        flex-grow: 0;
        min-height: 78px;
        padding-top: 32px;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: calc(50% - 20px);
          width: 40px;
          height: 4px;
          background-color: $color-black-op-25;
          border-radius: 4px;
        }
      }
      &__content {
        flex-grow: 1;
        overflow-y: auto;
      }
    }

    &__actions {
      display: flex;
      align-items: flex-start;
      flex-grow: 0;

      @include for-size(phone-landscape-down) {
        flex-wrap: wrap;
      }

      @include for-size(phone-portrait-down) {
        position: relative;
        padding-top: 12px;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: -20px;
          width: 100vw;
          border-top: 1px solid $color-gray-status-op-15;
        }
      }

      .esmp-button-wrapper {
        margin: 0 16px 16px 0;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
.additional-info-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.additional-info-icon {
  margin: 0 5px 0 0;
}
.view-ticket-additional-info {
  position: relative;
  &__link {
    min-height: 48px;
    position: absolute;
    top: -32px;
    right: 0;
    background-color: $color-white;
  }
}
.view-ticket {
  width: 100%;
  margin: 0 auto;
  display: flex;
  @include for-size(phone-landscape-down) {
    display: block;
  }

  &__info-link {
    margin-bottom: 28px;
    display: block;
  }

  &__main {
    flex-grow: 1;
    background: $color-white;
    border-radius: $base-border-radius * 2;
    padding: 32px;
    width: 100%;

    .view-ticket-collapser {
      margin-bottom: 20px;
      @include for-size(phone-portrait-down) {
        padding: 10px;
        .esmp-collapser__title {
          font-size: 13px;
        }
        .esmp-collapser__body {
          padding: 0 8px 8px 8px;
        }
      }
    }

    @include for-size(phone-portrait-down) {
      padding: 20px 20px 0;
      border-radius: $base-border-radius * 2 $base-border-radius * 2 0 0;
    }
  }
  &__sidebar {
    width: $right-column-width;
    flex-shrink: 0;
    margin-left: $gap;
    &-block {
      margin: 18px 0 0 0;
      padding: 20px 16px;
      background: #fff;
      border-radius: 16px;
      &:first-child {
        margin: 0;
      }
    }
    @include for-size(phone-landscape-down) {
      width: 100%;
      margin-left: 0;
    }
  }
  &__header {
    margin-bottom: 20px;

    &-title {
      margin: 8px 0 0 0;
    }
    &-subject {
      margin: 12px 0 0 0;
      font-size: 16px;
      line-height: 24px;
    }
    &-description {
      margin: 8px 0 0 0;
      font-size: 14px;
      line-height: 20px;
      color: rgba(16, 24, 40, 0.7);
      @include for-size(phone-portrait-down) {
        font-size: 13px;
      }
    }
    &-top-line {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-size: 14px;
      line-height: 20px;
      color: #B5B7C0;
      @include for-size(phone-portrait-down) {
        font-size: 13px;
      }
    }
  }
  &__gray-dot {
    display: inline-block;
    margin: 0 3px;
    font-size: 18px;
    line-height: 20px;
  }
  &__status {
    margin-left: 3px;
  }
  &__massive-fail {
    display: inline-flex;
    align-items: center;
    color: $color-error-night;
    font-size: 12px;
    line-height: 16px;
    margin-left: -6px;
    svg {
      width: 32px;
      height: 32px;
    }
  }
  &__add-watchers {
    cursor: pointer;
    font-size: 13px;
    line-height: 20px;
    margin: 14px 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #9466ff;
    &:hover {
      color: #70f;
    }
  }
  &__escalation {
    margin: 16px 0 0 0;
  }
  &__related-title {
    margin: 32px 0 0 0;
  }

  &__solution {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  &__info {
    .esmp-drawer-header {
      border-bottom: none;
      padding-bottom: 0;
    }
    .esmp-drawer-content {
      background-color: $color-grayscale-05;
    }
  }
}
.sidebar-block {
  &__title {
    margin: 0 0 20px 0;
  }
}
.ticket-info {
  &-row {
    margin: 12px 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    font-size: 12px;
    line-height: 16px;
    &:first-child {
      margin: 0;
    }

    &--align-top {
      align-items: flex-start;
    }
  }
  &-label {
    width: 95px;
    color: rgba(121, 126, 139, 1);
    flex-shrink: 0;
  }
  &-value {
    display: flex;
    flex-direction: row;
    align-items: center;

    &--column {
      flex-direction: column;
    }
  }
  &-avatar {
    margin: -2px 5px 0 0;
    flex-shrink: 0;
  }

  &__link {
    @include hover(true) {
      color: $color-client-portal-logo;
    }
  }
}
.ticket-watcher {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 0 0;
  &:first-child {
    margin: 0;
  }
}
.ticket-priority {
  display: inline-block;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  padding: 4px 8px 2px;
  border-radius: 8px;
}
.esmp-modal-footer .esmp-button-wrapper {
  @include for-size(phone-portrait-down) {
    margin: 6px 16px 6px 0;
  }
}
