
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.btc__group,
.btc__row {
  padding: 24px;
  border-radius: 12px;
  border: 1px #e8e8ee solid;
  background: #fff;
  margin-top: 20px;
  @include for-size(phone-portrait-down) {
    padding: 8px;
    margin-top: 12px;
  }
}
.btc__row-title {
  font-size: 16px;
  @include for-size(phone-portrait-down) {
    font-size: 14px;
  }
}
.ticket-checklist-item {
  font-size: 14px;
  margin-top: 20px;
  &--label {
    font-weight: bold;
  }
  &--value {
    margin-top: 4px;
  }

  @include for-size(phone-portrait-down) {
    font-size: 12px;
    margin-top: 8px;
  }
}
