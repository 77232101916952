
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.ticket-info-avatar {
  width: 16px;
  height: 16px;
}
